import React, { createContext, useState, useEffect, useCallback } from "react";
import moment from "moment";
import {
  useAuth,
  usePaddle,
  useModal,
  useAxiosDefaults,
  View,
  useData,
} from "ollui";
import { useHistory } from "react-router-dom";
// import portfolio from "./data/aggregate_level_plan_results.json";
// import buildings from "./data/building_level_plan_results.json";
// import { useFetch } from "./useFetch";
import { useUser } from "./useUser";
import { useMembers } from "./useMembers";
// import { useSocket } from "./useSocket";
import { useTeams } from "./useTeams";
import { useNotify } from "./useNotify";
import { useQuery } from "../hooks";

export const StoreContext = createContext({});

// https://medium.com/@ryanchenkie_40935/react-authentication-how-to-store-jwt-in-a-cookie-346519310e81
// https://hasura.io/blog/best-practices-of-using-jwt-with-graphql/

// console.log(process.env.NODE_ENV);

const isDev = process.env.NODE_ENV === "development";

const imageBaseUrl = "/";
const getTeamDisplayName = (user, selectedTeam) => {
  if (selectedTeam && selectedTeam.name) {
    return `${selectedTeam.name}`;
  }
  if (user.name) {
    return `${user.name}'s team`;
  }
  return "Your team";
};

const getInitialSelectedTeam = (teams) => {
  if (!teams || !teams.length) {
    return null;
  }

  const fallbackTeam = teams[0];
  const teamIdFromLocalStorage = localStorage.getItem("selectedTeamId");

  if (!teamIdFromLocalStorage) {
    return fallbackTeam;
  }

  const teamFromLocalStorage = teams.find(
    (d) => d.id === teamIdFromLocalStorage
  );

  if (teamFromLocalStorage) {
    return teamFromLocalStorage;
  }

  return fallbackTeam;
};

const getInitialSelectedHub = (hubs) => {
  if (!hubs || !hubs.length) {
    return null;
  }

  const fallback = hubs[0];
  const idFromLocalStorage = localStorage.getItem("selectedHubId");

  if (!idFromLocalStorage) {
    return fallback;
  }

  const itemFromLocalStorage = hubs.find((d) => d.id === idFromLocalStorage);

  if (itemFromLocalStorage) {
    return itemFromLocalStorage;
  }

  return fallback;
};

export const StoreProvider = ({ children }) => {
  const auth = useAuth();
  const [initialLoading, setInitialLoading] = useState(true);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [selectedHub, setSelectedHub] = useState(null);
  const [selectedRoute, setSelectedRoute] = useState(null);
  const { user, refreshUser } = useUser(auth.isAuthenticated, imageBaseUrl);
  const { setModal } = useModal();
  useAxiosDefaults({ auth, baseUrl: isDev ? "/" : "/api" });
  // console.log("selectedTeam", selectedTeam);

  const { members, refreshMembers } = useMembers(
    auth.isAuthenticated,
    selectedTeam
  );
  const { teams, invites, refreshTeams } = useTeams(auth.isAuthenticated);
  useNotify({ invites, auth, refreshTeams });

  const paddle = usePaddle({
    isAuthenticated: auth.isAuthenticated,
    user,
    selectedTeam,
  });

  const { data: gpsLatest, refresh: refreshGpsLatest } = useData({
    isAuthenticated: auth.isAuthenticated,
    defaultState: [],
    url: `/gps/latest/${selectedHub ? selectedHub.id : ""}`,
    skip: !selectedHub,
  });

  const { data: gpsAll, refresh: refreshGpsAll } = useData({
    isAuthenticated: auth.isAuthenticated,
    defaultState: [],
    url: `/gps/all/${selectedHub ? selectedHub.id : ""}`,
    skip: !selectedHub,
  });

  const { data: routes, refresh: refresRoutes } = useData({
    isAuthenticated: auth.isAuthenticated,
    defaultState: [],
    url: `/routes/${selectedHub ? selectedHub.id : ""}`,
    skip: !selectedHub,
  });

  const { data: hubs, refresh: refresHubs } = useData({
    isAuthenticated: auth.isAuthenticated,
    defaultState: [],
    url: `/hubs/${selectedTeam ? selectedTeam.id : ""}`,
    skip: !selectedTeam,
  });

  console.log("hubs", hubs);

  useEffect(() => {
    if (!selectedTeam || !selectedTeam.name) {
      const initialSelectedTeam = getInitialSelectedTeam(teams);
      setSelectedTeam(initialSelectedTeam);
      return;
    }
    const updatedTeam = teams.find((d) => d.id === selectedTeam.id);
    if (updatedTeam) {
      setSelectedTeam(updatedTeam);
    }
  }, [teams]);

  useEffect(() => {
    if (!selectedHub || !selectedHub.name) {
      const initialSelected = getInitialSelectedHub(hubs);
      setSelectedHub(initialSelected);
      return;
    }
    const updatedHub = teams.find((d) => d.id === selectedHub.id);
    if (updatedHub) {
      setSelectedHub(updatedHub);
    }
  }, [hubs]);

  useEffect(() => {
    if (!selectedTeam || !selectedTeam.id) {
      return;
    }
    localStorage.setItem("selectedTeamId", selectedTeam.id);
  }, [selectedTeam]);

  const tier = selectedTeam ? selectedTeam.tier : "";

  const teamDisplayName = getTeamDisplayName(user, selectedTeam);

  const triggerPayment = useCallback(async () => {
    if (!selectedTeam || !selectedTeam.id) {
      return;
    }
    const paymentLink = await paddle.getPaymentLink({
      teamId: selectedTeam.id,
    });
    setModal({
      title: "Add payment details",
      component: <View className="checkout-container"></View>,
    });
    setTimeout(
      () =>
        paddle.openPayment({
          email: user.email,
          // product: ****,
          passthrough: `{"teamId": "${selectedTeam && selectedTeam.id}"}`,
          override: paymentLink,
        }),
      500
    );
  }, [paddle, user, selectedTeam, setModal]);

  const query = useQuery();
  const history = useHistory();
  const selectedRouteQueryParam = query.get("route");

  useEffect(() => {
    if (history.location.pathname !== "/") {
      query.delete("route");
    }
  }, [history.location]);

  useEffect(() => {
    const newSelectedRoute = routes
      ? routes.find(({ id }) => id === Number(selectedRouteQueryParam))
      : null;
    if (
      !newSelectedRoute &&
      routes &&
      routes.length &&
      history.location.pathname === "/"
    ) {
      // console.log("setting query param");
      // query.set("route", routes[0].route);
      query.delete("route");
      query.append("route", routes[0].id);
      history.push({ search: query.toString() });
      return;
    }
    console.log("newSelectedRoute", newSelectedRoute);
    if (!newSelectedRoute) {
      return;
    }
    if (
      !selectedRoute ||
      (selectedRoute && newSelectedRoute.id !== selectedRoute.id)
    ) {
      setSelectedRoute(newSelectedRoute);
    }
    // console.log("selectedRoute", selectedRoute);
  }, [selectedRouteQueryParam, routes, selectedRoute, setSelectedRoute]);

  const selectedRouteIndexRaw = routes
    ? routes.findIndex(({ id }) => selectedRoute && id === selectedRoute.id)
    : 0;
  const selectedRouteIndex =
    selectedRouteIndexRaw >= 0 ? selectedRouteIndexRaw : 0;
  console.log("selectedRouteIndex", selectedRouteIndex);
  // console.log("selectedRouteQueryParam", selectedRouteQueryParam);

  const store = {
    auth,
    members,
    refreshMembers,
    teams,
    refreshTeams,
    refreshUser,
    invites,
    selectedTeam,
    setSelectedTeam,
    teamDisplayName,
    portfolio: {},
    buildings: {},
    tier,
    user,
    imageBaseUrl,
    initialLoading,
    triggerPayment,
    paddle,
    gpsLatest,
    gpsAll,
    routes,
    selectedRoute,
    selectedRouteIndex,
    hubs,
    selectedHub,
    refreshGpsLatest,
    refresRoutes,
  };

  useEffect(() => {
    moment.locale("en");
  }, []);

  useEffect(() => {
    auth.checkLoginStatus(() => setInitialLoading(false));
  }, []);

  useEffect(() => {
    if (!auth.isAuthenticated && !initialLoading) {
    }
  }, [auth.isAuthenticated && initialLoading]);

  return (
    <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
  );
};
