import React from "react";
import { View } from "ollui";
import { RiUser3Line } from "react-icons/ri";

const ProfileImage = ({ image, size = 70 }) => {
  return (
    <View extend={profileCSS({ size })}>
      {image ? (
        <View as="img" extend={profileImageCSS} src={image} />
      ) : (
        <RiUser3Line size={20} />
      )}
    </View>
  );
};

const profileCSS = ({ size }) => ({ theme: { grid, colors, shadows } }) => ({
  width: size - 2 * grid,
  height: size - 2 * grid,
  borderRadius: "50%",
  padding: grid / 2,
  border: `2px solid ${colors.bg}`,
  transition: "border-color 200ms ease-in-out",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
  background: colors.bg,
  boxShadow: shadows.big,
  color: colors.body,

  "&:hover": {
    borderColor: colors.primary,
    color: colors.primary,
  },
});

const profileImageCSS = ({ theme: { grid, colors } }) => ({
  width: "100%",
  height: "100%",
  borderRadius: "50%",
  objectFit: "cover",
  // background: "hotpink",
  // display: "flex",
  // justifyContent: "center",
  // alignItems: "center",
});

export default ProfileImage;
