import React from "react";
import { View, Spacer, Page, Text } from "ollui";
// import { useStore } from "../../store";
import { BillingDetails } from "./billing-details";

const Profile = () => {
  // const { user, teams } = useStore();

  return (
    <Page disableScroll limitContentWidth>
      {/* <Card extend={{ display: "flex" }}>
        <ProfileImage />
        <Spacer size={4} />
        <View
          extend={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Item label="Your email:" value={user.email} />
          <Spacer size={1} />
          <Item
            label="Member since:"
            value={<Date textVariant="small">{user.createdAt}</Date>}
          />
          <Spacer size={1} />
          <Item
            label="Member of:"
            value={`${teams.length} team${teams.length > 1 ? "s" : ""}`}
          />
        </View>
      </Card>
      <Spacer size={4} /> */}
      <BillingDetails />
    </Page>
  );
};

const Item = ({ label, value }) => (
  <View extend={{ display: "flex", alignItems: "baseline" }}>
    <Text variant="body" extend={{ flex: 1 }}>
      {label}
    </Text>
    <Spacer size={1} />
    <Text bold>{value}</Text>
  </View>
);

export default Profile;
