import { DefaultTheme } from "ollui";

const sideNavWidth = 300;

export default {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    contentBg: "#f5f5f5",
    bg: "#fff",
    border: "#eee",
    // secondaryBg: 'f5f5f5'
    navigationBg: "#fff",
    navigationBorder: "#f5f5f5",
    // navigationBg: "#2AA4FA",
    // navigationBorder: "#2A95FA",
    // primary: "#2EC2A4",
    // primary: "#222",
    // primary: "#8453D4",
    // primary: "#C875D3",
    // primary: "#222",
    // primary: "#7860D8",
    primary: "#5B92A2", // blueish
    primaryDark: "#544399",
    primaryLight: "#ccc",
    secondary: "#F58C8D",
    // primary: "#222",
    red: "#E33849",
    orange: "#F09848",
    yellow: "#EBCB8B",
    green: "#79C242",
    disabled: "#ddd",
    // red: "#f17173",
    // green: "#a3ceb8",
    // yellow: "#F5F366",
    // orange: "#F5A03A",
    bodySecondary: "#888",
    // green: '#B7E394',
    //   border: `#222`
  },
  font: "Roboto, sans-serif",

  //   #BF616A
  // #D08770
  // #EBCB8B
  // #A3BE8C
  // #B48EAD
  layout: {
    ...DefaultTheme.layout,
    radius: 10,
    // radius: 15,
    //   border: `#222`
  },
  breakpoints: {
    ...DefaultTheme.breakpoints,
    max: `@media (min-width: ${
      DefaultTheme.layout.maxContentWidth + sideNavWidth
    }px)`,
  },
  sideNavWidth,
  radius: 10,
  grid: 8,
};

// export default {
//   ...DefaultTheme,
//   colors: {
//     ...DefaultTheme.colors,
//     contentBg: "#F8FBFF",
//     bg: "#fff",
//     border: "#f5f5f5",
//     // secondaryBg: 'f5f5f5'
//     navigationBg: "#fff",
//     navigationBorder: "#f5f5f5",
//     // navigationBg: "#2AA4FA",
//     // navigationBorder: "#2A95FA",
//     primary: "#6C8CB0",
//     // primary: "#2EC2A4",
//     // primary: "#222",
//     red: "#E78B84",
//     green: "#85BBB2",
//     yellow: "#F5F366",
//     orange: "#F5A03A",
//     // green: '#B7E394',
//     //   border: `#222`
//   },
//   layout: {
//     ...DefaultTheme.layout,
//     radius: 20,
//     //   border: `#222`
//   },
//   grid: 8,
// };
