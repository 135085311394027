import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Page } from "../../components/atoms/page";
import { useStore } from "../../store";

const Logout = () => {
  const history = useHistory();
  const { auth } = useStore();

  // console.log(auth);

  useEffect(() => {
    console.log("Logging out");
    auth.logout();
    setTimeout(() => {
      history.push("/login");
    }, 2000);
  });
  return (
    <Page disableScroll limitContentWidth>
      logout
    </Page>
  );
};

export default Logout;
