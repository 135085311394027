import React, { useState, useMemo } from "react";
import { View, Text, Date, Spacer, Button, Card } from "ollui";
import Map from "../../components/organisms/map";
import { useStore } from "../../store";

const getMarkers = ({ gpsAll }) => {
  if (!gpsAll || !gpsAll.length) {
    return [];
  }
  return gpsAll.map((d) => ({
    id: d.time,
    lon: d.lon,
    lat: d.lat,
    name: d.time,
  }));
};

const MapInformation = () => {
  const { gpsLatest, routes, gpsAll, selectedRouteIndex } = useStore();
  const [isSingle, setIsSingle] = useState(true);
  // const [selectedRouteIndex, setSelectedRouteIndex] = useState(0);

  const markers = useMemo(() => getMarkers({ gpsAll }), [gpsAll]);

  const currentPosition = gpsLatest
    ? {
        id: gpsLatest.time,
        lon: gpsLatest.lon,
        lat: gpsLatest.lat,
        name: gpsLatest.time,
      }
    : {};

  return (
    <View extend={mapSectionCSS}>
      <Map
        currentPosition={currentPosition}
        markers={[]}
        selectedRouteIndex={selectedRouteIndex}
      />
    </View>
  );
};

const mapSectionCSS = ({ theme: { colors, radius, breakpoints } }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  zIndex: 0,
  width: "100%",
  height: "100%",
  background: colors.bg,
  // boxShadow: shadows.big,
  padding: 0,
  overflow: "hidden",
  borderRadius: radius,
  // display: "flex",

  // [breakpoints.onlyS]: {
  //   position: "absolute",
  //   top: 0,
  //   left: 0,
  //   zIndex: 0,
  //   width: "100%",
  //   height: "100%",
  //   maxHeight: "initial",
  //   flexDirection: "column",
  //   borderRadius: 0,
  // },
});

export default MapInformation;
