import { useState, useEffect, useCallback } from "react";
import axios from "axios";

export const defaultValue = {
  organization: {},
};

export const useUser = (isAuthenticated) => {
  const [user, setUser] = useState(defaultValue);

  const refreshUser = useCallback(() => {
    // console.log("refreshing user");
    axios
      .get("/user")
      .then((response) => {
        const { data: user } = response;
        // console.log("user", user);
        setUser(user);
      })
      .catch((err) => {
        // setLoginError(err.message);
      });
  }, []);

  useEffect(() => {
    if (!isAuthenticated) {
      setUser(defaultValue);
      return;
    }
    refreshUser();
  }, [isAuthenticated, refreshUser]);

  return {
    user,
    refreshUser,
  };
};
