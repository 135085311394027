import React from "react";
import { View } from "ollui";
import { useFela } from "react-fela";
import { motion } from "framer-motion";

export const LoginBackground = () => {
  const {
    theme: { colors },
  } = useFela();
  // console.log(colors);

  const path1 =
    "M37,-59C48.6,-57.3,59.3,-48.8,62.9,-37.9C66.5,-27,62.9,-13.5,61.3,-0.9C59.8,11.7,60.3,23.4,56,33.1C51.8,42.9,42.8,50.7,32.7,61.5C22.5,72.2,11.3,85.8,-1.6,88.5C-14.4,91.3,-28.9,83.2,-37.5,71.6C-46.1,60,-48.8,44.9,-55,32.4C-61.2,19.9,-70.7,9.9,-70.3,0.3C-69.8,-9.4,-59.3,-18.8,-49.2,-24.5C-39.1,-30.1,-29.4,-32.1,-21.3,-35.9C-13.1,-39.6,-6.6,-45.2,3,-50.5C12.6,-55.7,25.3,-60.7,37,-59Z";
  const path2 =
    "M35.7,-59.5C47.2,-55.1,58,-47.5,67.7,-37C77.4,-26.5,85.8,-13.3,86.1,0.2C86.4,13.6,78.6,27.3,69.7,38.9C60.7,50.6,50.6,60.3,38.8,66C26.9,71.6,13.5,73.3,-0.7,74.4C-14.8,75.6,-29.6,76.3,-42.9,71.5C-56.2,66.6,-68,56.4,-71.1,43.5C-74.3,30.7,-68.7,15.4,-68.1,0.3C-67.5,-14.7,-71.8,-29.3,-65.2,-36C-58.5,-42.7,-40.8,-41.4,-28.1,-45.1C-15.4,-48.8,-7.7,-57.5,2.2,-61.3C12.1,-65.1,24.2,-63.9,35.7,-59.5Z";
  const path3 =
    "M28.1,-44.7C38.4,-42.7,50.1,-39.2,52.8,-31.5C55.6,-23.8,49.4,-11.9,45.2,-2.4C41,7.1,38.8,14.1,38.5,26C38.2,37.9,39.7,54.6,33.5,66.2C27.4,77.9,13.7,84.6,3.1,79.3C-7.6,73.9,-15.1,56.6,-21.8,45.2C-28.5,33.9,-34.4,28.6,-46.2,22C-58.1,15.5,-76,7.7,-80.6,-2.7C-85.3,-13.1,-76.8,-26.3,-68.5,-39C-60.2,-51.6,-52,-63.9,-40.7,-65.3C-29.3,-66.7,-14.6,-57.3,-2.9,-52.3C8.9,-47.3,17.8,-46.8,28.1,-44.7Z";

  return (
    <View extend={rootCSS}>
      <motion.div
        initial="hidden"
        animate="visible"
        variants={{
          hidden: { opacity: 0 },
          visible: { opacity: 1 },
        }}
      >
        <View
          as="svg"
          viewBox="0 0 200 200"
          extend={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
          }}
        >
          <defs>
            <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="0%">
              <stop
                offset="0%"
                style={{
                  stopColor: colors.secondary,
                  stopOpacity: 1,
                }}
              />
              <stop
                offset="100%"
                style={{
                  stopColor: colors.primary,
                  stopOpacity: 1,
                }}
              />
            </linearGradient>
          </defs>
          <motion.path
            animate={{
              d: [path1, path3, path2],
            }}
            fill="url(#grad1)"
            transform="translate(150 140)"
            transition={{
              duration: 5,
            }}
          />
        </View>
        <View as="svg" viewBox="0 0 200 200">
          <defs>
            <linearGradient id="grad2" x1="0%" y1="0%" x2="100%" y2="0%">
              <stop
                offset="0%"
                style={{
                  stopColor: colors.primary,
                  stopOpacity: 1,
                }}
              />
              <stop
                offset="100%"
                style={{
                  stopColor: colors.secondary,
                  stopOpacity: 1,
                }}
              />
            </linearGradient>
          </defs>
          <motion.path
            animate={{
              d: [path1, path3, path2],
            }}
            fill="url(#grad2)"
            transform="translate(20 20)"
            transition={{
              duration: 5,
            }}
          />
        </View>
      </motion.div>
    </View>
  );
};

const rootCSS = ({ theme: { colors } }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  overflow: "hidden",
  zIndex: 0,
  background: colors.primary,
  pointerEvents: "none",
  // "& > path": {
  //   fill: colors.primary,
  // },
});
