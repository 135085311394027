import React from "react";
import ReactDOM from "react-dom";
import { ThemeProvider, StyleProvider, styleRenderer } from "ollui";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import theme from "./theme";

const renderer = styleRenderer();

const Root = () => (
  <React.StrictMode>
    <StyleProvider renderer={renderer}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </StyleProvider>
  </React.StrictMode>
);

ReactDOM.render(<Root />, document.getElementById("root"));

if (module.hot) {
  module.hot.accept("./App", () => {
    ReactDOM.render(<Root />, document.getElementById("root"));
  });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
