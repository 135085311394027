import React, { useCallback, useEffect, useState } from "react";
import { RiCheckboxCircleLine, RiCloseCircleLine } from "react-icons/ri";
import {
  View,
  Spinner,
  useModal,
  Spacer,
  Row,
  Cell,
  Button,
  MobileRow,
  CardHeader,
  Page,
  Card,
  Text,
  Date,
  BreadCrumbs,
  BreadCrumb,
} from "ollui";
import { useStore } from "../../store";
import ProfileImage from "./profile-image";
import Axios from "axios";

export const BillingDetails = () => {
  const { triggerPayment, selectedTeam } = useStore();
  const { setModal } = useModal();
  const [paddleDetails, setPaddleDetails] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const handlePaddleDetails = useCallback(() => {
    setModal({
      title: "Add payment details",
      component: <View className="checkout-container"></View>,
    });
    setTimeout(triggerPayment, 500);
  }, [setModal, triggerPayment]);

  useEffect(() => {
    console.log(selectedTeam);
    if (!selectedTeam) {
      return;
    }
    Axios.get(`/team/payment-details/${selectedTeam.id}`)
      .then(({ data }) => {
        setPaddleDetails(data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });

    // paddle.getDetails(selectedTeam.checkoutId).then((res, err) => {
    //   console.log("res", res);
    //   console.log("err", err);
    // });
  }, [selectedTeam]);

  // console.table(paddleDetails);
  return (
    <>
      <View extend={rootCSS}>
        <Card extend={paymentMethodCSS}>
          <CardHeader title="Payment method">
            <Link href={paddleDetails.update_url} variant="clear">
              Update
            </Link>
          </CardHeader>
          <Spacer size={3} />
          {!paddleDetails ||
            (isLoading && (
              <View extend={{ display: "flex", justifyContent: "center" }}>
                <Spinner />
              </View>
            ))}
          {paddleDetails && paddleDetails.payment_information && (
            <View extend={{ display: "flex", justifyContent: "center" }}>
              <CreditCard data={paddleDetails.payment_information} />
            </View>
          )}
        </Card>
        <Spacer size={4} />
        <Card extend={paymentHistoryCSS}>
          <CardHeader title="Payment history"></CardHeader>
          {isLoading && (
            <View extend={{ display: "flex", justifyContent: "center" }}>
              <Spinner />
            </View>
          )}
          {paddleDetails &&
            paddleDetails.payments &&
            paddleDetails.payments.map(
              ({ id, amount, currency, is_paid, payout_date, receipt_url }) => (
                <React.Fragment key={id}>
                  <Row extend={hideOnMobileCSS}>
                    <Cell extend={{ flex: 0 }}>
                      {is_paid ? (
                        <RiCheckboxCircleLine size={24} />
                      ) : (
                        <RiCloseCircleLine size={24} />
                      )}
                    </Cell>
                    <Cell>
                      <Text>{`${currency} ${amount}`}</Text>
                    </Cell>
                    <Cell>
                      <Text>
                        <Date>{payout_date}</Date>
                      </Text>
                    </Cell>
                    <Cell extend={{ flex: 0 }}>
                      <Link href={receipt_url} variant="clear">
                        View receipt
                      </Link>
                    </Cell>
                  </Row>
                  <MobileRow
                    items={[
                      <View extend={{ display: "flex", alignItems: "center" }}>
                        {is_paid ? (
                          <RiCheckboxCircleLine size={24} />
                        ) : (
                          <RiCloseCircleLine size={24} />
                        )}
                        <Spacer />
                        <Text>
                          {is_paid ? "Payment successful" : "Payment failed"}
                        </Text>
                      </View>,
                      <View>
                        <Text>{`Amount: ${currency} ${amount}`}</Text>
                      </View>,
                      <View>
                        <Text>
                          {`Payment date: `}
                          <Date>{payout_date}</Date>
                        </Text>
                      </View>,
                      <Link href={receipt_url} variant="clear">
                        View receipt
                      </Link>,
                    ]}
                  />
                </React.Fragment>
              )
            )}
        </Card>
      </View>
      <Spacer size={4} />
      <Card>
        <CardHeader title="Danger zone"></CardHeader>
        {paddleDetails && paddleDetails.cancel_url && (
          <View>
            <Spacer size={3} />
            <Link href={paddleDetails.cancel_url} variant="destructive">
              Cancel subscription
            </Link>
            <Spacer />
            <Text diffuse>
              Warning: This will cancel all monitoring and alerting on your
              sites
            </Text>
          </View>
        )}
      </Card>
    </>
  );
};

const Link = ({ href, children, ...props }) => (
  <a
    href={href}
    target="_blank"
    rel="noopener noreferrer"
    style={{ textDecoration: "none" }}
  >
    <Button {...props}>{children}</Button>
  </a>
);

const CreditCard = ({ data }) => (
  <View extend={creditCardCSS}>
    <View extend={creditCardNumberCSS}>
      <Text
        variant="large"
        bold
      >{`*** **** **** ${data.last_four_digits}`}</Text>
      <Spacer />
      <Text variant="medium">{data.expiry_date}</Text>
    </View>
    {/* <View extend={creditCardTypeCSS}>
      <Text>{data.card_type}</Text>
    </View> */}
  </View>
);

const rootCSS = ({ theme: { breakpoints } }) => ({
  display: "flex",

  [breakpoints.onlyS]: {
    flexDirection: "column",
  },
});

const hideOnMobileCSS = ({ theme: { breakpoints } }) => ({
  [breakpoints.onlyS]: {
    display: "none",
  },
});

const paymentMethodCSS = () => ({
  flex: 0,
});

const paymentHistoryCSS = () => ({
  flex: 1,
});

const creditCardCSS = ({ theme: { colors, shadows } }) => ({
  position: "relative",
  width: 300,
  height: 200,
  boxShadow: shadows.big,
  borderRadius: 15,
  background: colors.bg,
  border: `2px solid ${colors.border}`,
});

const creditCardNumberCSS = ({ theme: { grid } }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  paddingTop: 2 * grid,
});

const creditCardExpiryCSS = ({ theme: { colors, shadows } }) => ({
  position: "absolute",
  top: 0,
  left: 0,
});

const creditCardTypeCSS = ({ theme: { colors, shadows } }) => ({
  position: "absolute",
  top: 0,
  left: 0,
});
