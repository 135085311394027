import React, { useState, useLayoutEffect } from "react";
import { Button, Spacer, useNotifications } from "ollui";

export const useNotify = ({ invites, auth, refreshTeams }) => {
  const { triggerNotification, closeNotification } = useNotifications();

  useLayoutEffect(() => {
    if (!triggerNotification || !auth.isAuthenticated) {
      return;
    }
    if (invites.length) {
      invites.forEach(({ team, invite }) => {
        const id = `invite-${team.name}`;
        triggerNotification({
          title: "You have been invited to a team",
          subTitle: team.name,
          hideCloseButton: true,
          id,
          buttons: (
            <InviteButtons
              auth={auth}
              invite={invite}
              onClose={() => {
                closeNotification(id);
                refreshTeams();
              }}
            />
          ),
        });
      });
    }
  }, [invites, auth.isAuthenticated]);

  return {};
};

const InviteButtons = ({ invite, auth, onClose }) => {
  const [loading, setLoading] = useState(false);

  return (
    <>
      <Button
        variant="clear"
        disabled={loading === "accept"}
        isLoading={loading === "decline"}
        onClick={() => {
          setLoading("decline");
          auth.declineInvitation({ token: invite.inviteToken }).then(() => {
            setLoading(false);
            onClose();
          });
        }}
      >
        Decline
      </Button>
      <Spacer />
      <Button
        onClick={() => {
          setLoading("accept");
          auth.confirmInvitation({ token: invite.inviteToken }).then(() => {
            setLoading(false);
            onClose();
          });
        }}
        isLoading={loading === "accept"}
      >
        Accept
      </Button>
    </>
  );
};
