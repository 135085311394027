import React, { useState, useEffect, useCallback } from "react";
import { Link, useLocation } from "react-router-dom";
import { VscLocation } from "react-icons/vsc";
import {
  VscDebugBreakpointLogUnverified,
  VscDebugBreakpointData,
  VscCreditCard,
  VscSettingsGear,
  VscSignOut,
  VscAdd,
} from "react-icons/vsc";
import { RiBatteryChargeLine, RiBatteryLine } from "react-icons/ri";
import { View, Text, Spacer, Date, Button, Spinner } from "ollui";
import { useStore } from "../../../store";

export const HubInfo = ({ extend }) => {
  const { selectedHub, gpsLatest } = useStore();

  console.log("selectedHub", selectedHub);
  // const battery = 60;
  const { battery = "-" } = selectedHub || {};
  const BatteryIcon = battery > 0 ? RiBatteryLine : RiBatteryChargeLine;

  return (
    <View extend={rootCSS}>
      {selectedHub && (
        <>
          <View extend={batteryCSS}>
            <View extend={batteryStatusContainerCSS}>
              <View extend={batteryStatusCSS({ battery })} />
            </View>
            <View extend={batteryIconCSS}>
              <BatteryIcon size={40} />
            </View>
            <Text variant="small" extend={batteryTextCSS}>
              {battery > 0 ? `${battery}%` : "Plugged in"}
            </Text>
          </View>
          <Spacer size={2} />
          <View>
            <Text variant="medium" bold>
              {selectedHub.name}
            </Text>

            <Spacer size={0.5} />
            <Text variant="body">
              Last updated:{" "}
              {gpsLatest && <Date variant="ago">{gpsLatest.time}</Date>}
            </Text>
            <Text variant="small" diffuse>
              {gpsLatest && <Date>{gpsLatest.time}</Date>}
            </Text>
          </View>
        </>
      )}
    </View>
  );
};

const rootCSS = ({ theme: { breakpoints, grid, colors, radius } }) => ({
  position: "relative",
  display: "flex",
  // flexDirection: "column",
  // justifyContent: "center",
  alignItems: "center",
  // background: "red",
  width: "100%",
  // flexShrink: 0,
  // height: "100%",
  // borderRight: `1px solid ${colors.border}`,
  // background: colors.contentBg,
  // padding: 2 * grid,
  // paddingLeft: grid,
  // overflowX: "hidden",
  // display: "flex",
  // flexDirection: "column",
  borderRadius: radius,
  border: `1px solid ${colors.border}`,
});

const batteryCSS = ({ theme: { shadows, grid, colors, radius } }) => ({
  position: "relative",
  width: 70,
  height: 70,
  // borderRadius: radius,
  // boxShadow: `inset ${shadows.big}`,
  background: colors.contentBg,
  overflow: "hidden",
});

const batteryIconCSS = ({
  theme: { breakpoints, grid, colors, sideNavWidth },
}) => ({
  position: "relative",
  width: "100%",
  height: "100%",
  zIndex: 1,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  // width: "auto",
  color: colors.diffuse,
});

const batteryStatusContainerCSS = ({ theme: { colors } }) => ({
  position: "absolute",
  top: 26,
  left: 21,
  zIndex: 0,
  width: 25,
  height: 18,
  background: colors.border,
});

const batteryStatusCSS =
  ({ battery }) =>
  ({ theme: { breakpoints, grid, colors, sideNavWidth } }) => ({
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 0,
    width: `${battery}%`,
    height: "100%",
    background: colors.green,
  });

const batteryTextCSS = () => ({
  position: "absolute",
  bottom: 8,
  left: 0,
  zIndex: 0,
  textAlign: "center",
  width: "100%",
});
