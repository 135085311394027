import React, { useState, useEffect, useCallback } from "react";
import { Link, useLocation } from "react-router-dom";
import { VscLocation } from "react-icons/vsc";
import moment from "moment";
import {
  VscDebugBreakpointLogUnverified,
  VscDebugBreakpointLog,
  VscCreditCard,
  VscSettingsGear,
  VscSignOut,
  VscAdd,
} from "react-icons/vsc";
import { View, Text, Spacer, Date, Button } from "ollui";

const getLocation = (location, id) => {
  if (!location) {
    return "";
  }
  const { context } = location;
  const contextItem = context.find((item) => item.id.includes(id));
  return contextItem.text;
};

export const Trip = ({ trip, isSelected }) => {
  const { id, start, end, to, startLocation, endLocation, distance } = trip;
  console.log("startLocation", startLocation);
  return (
    <View as={Link} to={`/?route=${id}`} extend={tripCSS({ isSelected })}>
      {/* {Icon && <Icon size={24} />} */}
      {/* <Spacer size={2} /> */}
      <View>
        <Text>
          <Date variant="full">{start}</Date>
        </Text>
        <Text>
          <Date variant="full">{end}</Date>
        </Text>
        <Text>Distance: {`${Math.round(distance / 1000)}km`}</Text>
        <Text>Start: {getLocation(startLocation, "place")}</Text>
        <Text>End: {getLocation(endLocation, "place")}</Text>
      </View>
    </View>
  );
};
{
  /* <Item
  start={start}
  end={end}
  icon={
    isSelected
      ? VscDebugBreakpointLog
      : VscDebugBreakpointLogUnverified
  }
  to={`/?route=${id}`}
  isSelected={ selectedRoute && id === selectedRoute.id}
  key={id}
/> */
}

const itemHeight = 100;

const tripCSS =
  ({ isSelected }) =>
  ({ theme: { colors, grid, radius } }) => ({
    // padding: `0 ${4 * grid}px`,
    position: "relative",
    display: "flex",
    textDecoration: "none",
    cursor: "pointer",
    padding: 2 * grid,
    zIndex: 1,
    height: 100,
    alignItems: "center",
    borderRadius: radius,
    transition: "background 150ms ease-in-out",
    background: colors.bg,

    "> svg": {
      transition: "color 150ms ease-in-out",
      color: colors.body,
    },

    "&:hover": {
      background: colors.border,
    },

    ...(isSelected && {
      background: colors.contentBg,
      "& > p": {
        fontWeight: "bold",
        // color: "white",
      },
      "& > svg": {
        color: colors.primary,
      },

      "&:hover": {
        background: colors.primary,
        "& > p": {
          color: "white",
        },
      },
    }),
    "&:hover > span": {
      color: colors.primary,
    },

    "> label": {
      opacity: 0,
      transform: `translateX(${-grid}px)`,
      transition: "all 200ms ease-in-out",
    },

    "&:hover > label": {
      opacity: 1,
      transform: "translateX(0)",
    },
  });

const selectedCSS =
  ({ selectedRouteIndex }) =>
  ({ theme: { colors, shadows, radius } }) => ({
    position: "absolute",
    top: 0,
    right: 0,
    width: "100%",
    height: itemHeight,
    background: colors.primary,
    zIndex: 0,
    // boxShadow: shadows.big,
    ...(selectedRouteIndex >= 0 && {
      transform: `translateY(${selectedRouteIndex * 50}px)`,
    }),
    opacity: selectedRouteIndex >= 0 ? 1 : 0,
    transition: "all 150ms ease-in-out",
    borderRadius: radius,

    "& > *": {
      color: "white",
      fill: "white",
    },
  });
