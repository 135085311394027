import { useState, useEffect } from "react";
import axios from "axios";

const defaultState = {
  teams: [],
  invites: [],
};
export const useTeams = (isAuthenticated) => {
  const [data, setData] = useState(defaultState);

  const refreshData = () => {
    if (!isAuthenticated) {
      setData(defaultState);
      return;
    }

    axios
      .get("/teams")
      .then(({ data: responseData }) => {
        // console.log("responseData", responseData);
        setData(responseData);
      })
      .catch((err) => {
        // setLoginError(err.message);
      });
  };

  useEffect(refreshData, [isAuthenticated]);

  return { ...data, refreshTeams: refreshData };
};
