import React, { useState, useEffect } from "react";
import { View, Card, Spinner, Spacer } from "ollui";
import { useHistory } from "react-router-dom";
import { useStore } from "../../store";
import { LoginBackground } from "./background";
import { motion } from "framer-motion";
import { Form } from "./form";

const Login = () => (
  <>
    {/* <LoginBackground /> */}
    <Inner />
  </>
);

const Inner = () => {
  const { auth } = useStore();
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    // console.log("auth", auth);
    if (auth.isAuthenticated) {
      history.push("/");
    }
  }, []);

  return (
    <View extend={rootCSS}>
      {/* <View extend={darkBackgroundCSS} as={animated.div}>
        <Image src="/building.jpg" extend={imageCSS} />
      </View> */}

      <View
        as={motion.div}
        initial="hidden"
        animate="visible"
        variants={{
          hidden: { opacity: 0, scale: 0 },
          visible: { opacity: 1, scale: 1 },
        }}
        transition={
          {
            // delay: 2,
            // duration: 0.5,
          }
        }
        extend={contentCSS}
      >
        <Card extend={cardCSS}>
          <View extend={logoCSS}>
            <View
              as="img"
              src="../logo.svg"
              extend={{
                width: "100%",
                // height: 60,
              }}
            />
          </View>
          <Spacer size={8} />
          <View
            extend={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 1,
              pointerEvents: "none",
              opacity: isLoading ? 1 : 0,
              transition: "opacity 300ms ease-in-out",
            }}
          >
            <Spinner />
          </View>
          <Form isLoading={isLoading} setIsLoading={setIsLoading} />
        </Card>
      </View>
    </View>
  );
};

const rootCSS = ({ theme: { grid } }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  display: "flex",
  width: "100%",
  height: "100%",
  justifyContent: "center",
  // alignItems: "center",
  padding: 2 * grid,
});

const contentCSS = ({ theme: { grid } }) => ({
  width: "100%",
  maxHeight: "100%",
  height: "100%",
  maxWidth: 600,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const cardCSS = ({ theme: { grid, breakpoints, shadows } }) => ({
  // padding: 40,
  width: "100%",
  maxHeight: "100%",
  overflowY: "scroll",
  padding: 8 * grid,
  boxShadow: shadows.big,
  // padding: `${3 * grid}px ${8 * grid}px ${8 * grid}px ${8 * grid}px`,

  [breakpoints.onlyS]: {
    padding: 3 * grid,
  },
  // opacity: 0,
});

const logoCSS = () => ({
  width: "40%",
  // height: "5vh",
  margin: "0 auto",
  // display: "flex",
  // justifyContent: "flex-end",
});

const rightCSS = ({ theme: { colors, breakpoints, grid } }) => ({
  flex: 1,
  display: "flex",
  alignItems: "center",
  padding: 4 * grid,

  [breakpoints.onlyS]: {
    display: "none",
  },
});

const darkBackgroundCSS = ({ theme: { colors } }) => ({
  background: colors.primary,
  position: "absolute",
  width: "100%",
  height: "100%",
  zIndex: 0,
});

const imageCSS = () => ({
  position: "absolute",
  width: "100%",
  height: "100%",
  top: 0,
  left: 0,
  objectFit: "cover",
  opacity: 0.2,
});

export default Login;
