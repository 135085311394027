import React from "react";
import Helmet from "react-helmet";
import { useFela } from "react-fela";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import {
  View,
  Spinner,
  NotificationsProvider,
  ModalProvider,
  Modal,
} from "ollui";
// import Header from "./components/organisms/header";
import { SideNav } from "./components/organisms/side-nav";
import pages from "./pages";
import Login from "./pages/login";
import { Invitation } from "./pages/invitation";
// import Sites from "./components/organisms/sites";
// import Settings from "./pages/settings";
import { useStore, StoreProvider } from "./store";

// console.log(pages);

const App = () => {
  return (
    <View extend={rootCSS}>
      <Router>
        <NotificationsProvider>
          <ModalProvider>
            <StoreProvider>
              <Inner />
            </StoreProvider>
            <Modal />
          </ModalProvider>
        </NotificationsProvider>
      </Router>
    </View>
  );
};

const Inner = () => {
  const { auth, user, initialLoading } = useStore();
  const { theme } = useFela();

  return (
    <View extend={rootCSS}>
      <Helmet>
        {/* <!-- Chrome, Firefox OS and Opera --> */}
        <meta name="theme-color" content={theme.colors.bg} />
        {/* <!-- Windows Phone --> */}
        <meta name="msapplication-navbutton-color" content={theme.colors.bg} />
        {/* <!-- iOS Safari --> */}
        <meta
          name="apple-mobile-web-app-status-bar-style"
          content={theme.colors.bg}
        />
      </Helmet>
      {/* {auth.isAuthenticated && <Header />} */}

      <Switch>
        <Route
          path="/login"
          exact
          render={({ location }) =>
            !auth.isAuthenticated ? (
              <Login />
            ) : (
              <Redirect
                to={{
                  pathname: "/",
                }}
              />
            )
          }
        />
        <View extend={rootCSS}>
          <View extend={appCSS}>
            <SideNav />
            <View extend={contentCSS}>
              <Route
                path="/invitation/:token"
                exact
                render={({ location }) =>
                  !auth.isAuthenticated ? (
                    <Invitation />
                  ) : (
                    <Redirect
                      to={{
                        pathname: "/",
                      }}
                    />
                  )
                }
              />
              {!auth.isAuthenticated && !initialLoading && (
                <Redirect to="/login" />
              )}
              {auth.isAuthenticated &&
                pages
                  .filter((page) => !(!user.admin && page.adminOnly))
                  .map(({ path, Component }) => (
                    <Route
                      exact
                      path={path}
                      key={path}
                      render={({ location }) => <Component />}
                    />
                  ))}
            </View>
          </View>
        </View>
      </Switch>

      {initialLoading && (
        <View extend={initalLoadingCSS}>
          <Spinner />
        </View>
      )}
    </View>
  );
};

const rootCSS = ({ theme: { colors, grid, breakpoints } }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  background: colors.contentBg,

  [breakpoints.max]: {
    padding: 3 * grid,
  },
});

const appCSS = ({
  theme: { grid, colors, sideNavWidth, layout, shadows, breakpoints, radius },
}) => ({
  // padding: 2 * grid,
  width: "100%",
  height: "100%",
  maxWidth: sideNavWidth + layout.maxContentWidth,
  margin: "auto",
  // maxWidth: 1400,
  // margin: "0 auto",
  background: colors.contentBg,
  // boxShadow: shadows.big,
  // borderRadius: radius,
  display: "flex",
  overflow: "hidden",

  [breakpoints.max]: {
    boxShadow: shadows.big,
    borderRadius: radius,
  },
});

const contentCSS = ({
  theme: { grid, colors, shadows, radius, breakpoints },
}) => ({
  position: "relative",
  // padding: grid,
  // background: "red",
  // padding: 3 * grid,
  flex: 1,
  overflowY: "scroll",
  // overflowX: "hidden",
  // background: colors.bg,
  // boxShadow: shadows.big,
  // borderRadius: radius,
  [breakpoints.max]: {
    border: `10px solid ${colors.bg}`,
    borderTopRightRadius: radius,
    borderBottomRightRadius: radius,
  },
});

const initalLoadingCSS = ({ theme: { colors } }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  background: colors.bg,
  zIndex: 10,
});

export default App;
