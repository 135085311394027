import React, { useMemo } from "react";
import ReactMapboxGl, {
  Marker,
  ZoomControl,
  Layer,
  Feature,
  Source,
  GeoJSONLayer,
} from "react-mapbox-gl";
import { motion } from "framer-motion";
import { VscLocation } from "react-icons/vsc";
import { LngLatBounds } from "mapbox-gl";
import { Link } from "react-router-dom";
// import DrawControl from "react-mapbox-gl-draw";
// import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import { useFela } from "react-fela";
import { View } from "ollui";
// import "mapbox-gl/dist/mapbox-gl.css";
import { useStore } from "../../../store";

export const CurrentPositionMarker = () => {
  const { gpsLatest: currentPosition } = useStore();
  const { theme, renderer } = useFela();

  const keyframes = () => ({
    "0%": { transform: "scale(0.8)" },
    "80%": { transform: "scale(1)" },
    "100%": { transform: "scale(0.8)" },
  });

  if (!currentPosition) {
    return null;
  }

  // const singleMarkerKeyframes = renderer.renderKeyframe(keyframes, {});
  console.log("lala");

  return (
    <Marker
      key={currentPosition.time}
      coordinates={[currentPosition.lon, currentPosition.lat]}
      anchor="bottom"
    >
      <View extend={basicMarkerCSS}></View>
    </Marker>
  );

  // return (
  //   <Marker
  //     key={currentPosition.time}
  //     coordinates={[currentPosition.lon, currentPosition.lat]}
  //     anchor="bottom"
  //   >
  //     <View extend={currentPositionCSS}>
  //       <View
  //         as={motion.div}
  //         extend={pulseCSS}
  //         animate={{
  //           scale: [1, 0.7, 1],
  //           background: [
  //             theme.colors.primary,
  //             theme.colors.primaryLight,
  //             theme.colors.primary,
  //           ],
  //           // rotate: [0, 0, 270, 270, 0],
  //           // borderRadius: ["20%", "20%", "50%", "50%", "20%"],
  //         }}
  //         transition={{
  //           duration: 2,
  //           ease: "easeInOut",
  //           repeat: Infinity,
  //         }}
  //       >
  //         <View
  //           as={motion.div}
  //           extend={[pulse2CSS, pulse3CSS]}
  //           animate={{
  //             scale: [1, 0.7, 1],
  //             background: [
  //               theme.colors.primaryLight,
  //               theme.colors.primary,
  //               theme.colors.primaryLight,
  //             ],
  //             // rotate: [0, 0, 270, 270, 0],
  //             // borderRadius: ["20%", "20%", "50%", "50%", "20%"],
  //           }}
  //           transition={{
  //             duration: 2,
  //             ease: "easeInOut",
  //             repeat: Infinity,
  //           }}
  //         />
  //         <View
  //           as={motion.div}
  //           extend={pulse2CSS}
  //           animate={{
  //             scale: [1, 0.7, 1],
  //             background: [
  //               theme.colors.primaryDark,
  //               theme.colors.primary,
  //               theme.colors.primaryDark,
  //             ],
  //             // rotate: [0, 0, 270, 270, 0],
  //             // borderRadius: ["20%", "20%", "50%", "50%", "20%"],
  //           }}
  //           transition={{
  //             duration: 2,
  //             ease: "easeInOut",
  //             repeat: Infinity,
  //           }}
  //         />
  //       </View>
  //     </View>
  //   </Marker>
  // );
};

const currentPositionSize = 40;

const currentPositionCSS = ({ theme: { colors } }) => ({
  display: "block",
  // width: currentPositionSize,
  // height: currentPositionSize,
  // background: colors.primary,
  // borderRadius: "50%",
});

const pulseCSS = ({ theme: { breakpoints, grid, colors, radius } }) => ({
  position: "relative",
  width: currentPositionSize,
  height: currentPositionSize,
  borderRadius: "50%",
  background: colors.primary,
  margin: "0 auto",
});

const pulse2CSS = ({ theme: { breakpoints, grid, colors, radius } }) => ({
  position: "absolute",
  top: "15%",
  left: "15%",
  width: "70%",
  height: "70%",
  borderRadius: "50%",
  background: colors.primary,
  margin: "0 auto",
});

const pulse3CSS = ({ theme: { breakpoints, grid, colors, radius } }) => ({
  position: "absolute",
  top: "5%",
  left: "5%",
  width: "90%",
  height: "90%",
});

const markerSize = 24;

const basicMarkerCSS = ({ theme: { colors, shadows } }) => ({
  display: "block",
  width: markerSize,
  height: markerSize,
  // background: "orange",
  background: colors.primary,
  borderRadius: "50%",
  // transition: 'border 200ms ease-out 600ms',
  border: `4px solid ${colors.secondaryBg}`,
  boxShadow: shadows.big,
});
