import React, { useState, useEffect, useCallback } from "react";
import { Link, useLocation } from "react-router-dom";
import { VscLocation } from "react-icons/vsc";
import {
  VscDebugBreakpointLogUnverified,
  VscDebugBreakpointLog,
  VscCreditCard,
  VscSettingsGear,
  VscSignOut,
  VscAdd,
} from "react-icons/vsc";
import { View, Text, Spacer, Date, Button } from "ollui";
import ProfileImage from "../../atoms/profile-image";
import { useStore } from "../../../store";
import { HubInfo } from "./hub-info";
import { Trip } from "./trip";

export const MOBILE_HEADER_HEIGHT = 50;

const itemSize = 70;

const getInitialExpandedState = (buildingGroups) =>
  buildingGroups.reduce((a, b) => ({ ...a, [b.name]: false }), {});

export const SideNav = ({ extend }) => {
  const {
    sites,
    selectedRoute,
    user,
    selectedRouteIndex,
    routes,
    gpsLatest,
    selectedHub,
    refreshGpsLatest,
    refresRoutes,
  } = useStore();
  const [expanded, setExpanded] = useState(null);
  const [mobileNavigationOpen, setMobileNavigationOpen] = useState(false);
  const { pathname: currentPage } = useLocation();

  console.log("selectedHub", selectedHub);

  const refresh = useCallback(() => {
    refreshGpsLatest();
    refresRoutes();
  }, [refreshGpsLatest, refresRoutes]);

  return (
    <>
      {/* <View extend={mobileHeaderCSS}>
        <View
          as="button"
          extend={mobileHeaderSideCSS}
          onClick={() => setMobileNavigationOpen(!mobileNavigationOpen)}
        >
          {!mobileNavigationOpen ? <RiMenu2Line /> : <RiCloseLine />}
        </View>
        <View as={CustomLink} to="/" extend={logoMobileCSS}>
          sinom
        </View>
      </View> */}
      <View as="nav" extend={rootCSS}>
        <HubInfo />
        <View as={Link} to="/" extend={logoCSS}>
          <View
            as="img"
            src="../logo.svg"
            extend={{
              width: "100%",
              // height: 60,
            }}
          />
        </View>
        <Spacer size={2} />
        <View extend={{ display: "flex", justifyContent: "center" }}>
          <Button subVariant="small" onClick={refresh}>
            Refresh
          </Button>
        </View>
        {/* <View extend={{ display: "flex", justifyContent: "center" }}>
          <Button variant="clear" subVariant="icon">
            {"<"}
          </Button>
          <Spacer />
          <Button variant="clear" subVariant="icon">
            {">"}
          </Button>
        </View> */}
        <Spacer size={2} />
        <Text variant="medium" bold extend={tripsHeaderCSS}>
          Trips
        </Text>
        <Spacer size={2} />
        <View extend={itemsCSS}>
          {routes &&
            routes.length &&
            routes.map((route, index) => (
              <Trip
                trip={route}
                key={route.id}
                isSelected={selectedRoute && route.id === selectedRoute.id}
              />
            ))}
          <View extend={selectedCSS({ selectedRouteIndex })} />
          {/* <View
            as={Link}
            to="/add-website"
            extend={domainCSS({ isSelected: false })}
          >
            <VscAdd size={18} />
            <Spacer />
            <Text>Add a website</Text>
          </View> */}
        </View>
        <Spacer size={2} />
        <View extend={settingsCSS}>
          <View extend={profileCSS}>
            <ProfileImage />
            <Spacer />
            <Text variant="small">{user.email}</Text>
          </View>
          <ProfileItem icon={VscSettingsGear} to="/settings">
            Settings
          </ProfileItem>
          {/* <ProfileItem icon={VscCreditCard} to="/billing">
            Billing
          </ProfileItem> */}
          <ProfileItem icon={VscSignOut} to="/logout">
            Logout
          </ProfileItem>
        </View>
      </View>
    </>
  );
};

const ProfileItem = ({ icon: Icon, to, isSelected, children }) => (
  <View as={Link} to={to} extend={profileItemCSS}>
    {Icon && <Icon size={18} />}
    <Spacer />
    <Text>{children}</Text>
  </View>
);

const Item = ({ start, end, icon: Icon, to, isSelected, active }) => {
  // console.log("activeVisitors", activeVisitors);
  return (
    <View as={Link} to={to} extend={domainCSS({ isSelected })}>
      {Icon && <Icon size={24} />}
      <Spacer size={2} />
      <View>
        <Text>S: {start}</Text>
        <Text>E: {end}</Text>
      </View>
    </View>
  );
};

const rootCSS = ({ theme: { breakpoints, grid, colors, sideNavWidth } }) => ({
  position: "relative",
  // display: "flex",
  // justifyContent: "center",
  // alignItems: "flex-start",
  // background: "red",
  width: sideNavWidth,
  flexShrink: 0,
  height: "100%",
  // borderRight: `1px solid ${colors.border}`,
  background: colors.bg,
  padding: 2 * grid,
  // paddingLeft: grid,
  overflowX: "hidden",
  display: "flex",
  flexDirection: "column",
});

const logoCSS = ({ theme: { grid } }) => ({
  width: "100%",
  padding: `${4 * grid}px ${4 * grid}px`,
  // padding: 0,

  "& > img": {
    width: "100%",
    height: "auto",
  },
});

const tripsHeaderCSS = ({ theme: { grid } }) => ({
  // paddingLeft: 2 * grid,
});

const itemsCSS = ({ theme: { breakpoints, grid, colors } }) => ({
  position: "relative",
  flex: 1,
  // display: "flex",
  // justifyContent: "center",
  // alignItems: "flex-start",
  // background: "red",
  // maxWidth: 150,
  height: "auto",
  // padding: grid,
  overflowX: "hidden",
});

const itemHeight = 50;

const domainCSS =
  ({ isSelected }) =>
  ({ theme: { colors, grid, radius } }) => ({
    // padding: `0 ${4 * grid}px`,
    position: "relative",
    display: "flex",
    textDecoration: "none",
    cursor: "pointer",
    padding: 2 * grid,
    zIndex: 1,
    height: 50,
    alignItems: "center",
    borderRadius: radius,
    transition: "background 150ms ease-in-out",
    background: colors.bg,

    "> svg": {
      transition: "color 150ms ease-in-out",
      color: colors.body,
    },

    "&:hover": {
      background: colors.border,
    },

    ...(isSelected && {
      background: colors.contentBg,
      "& > p": {
        fontWeight: "bold",
        // color: "white",
      },
      "& > svg": {
        color: colors.primary,
      },

      "&:hover": {
        background: colors.primary,
        "& > p": {
          color: "white",
        },
      },
    }),
    "&:hover > span": {
      color: colors.primary,
    },

    "> label": {
      opacity: 0,
      transform: `translateX(${-grid}px)`,
      transition: "all 200ms ease-in-out",
    },

    "&:hover > label": {
      opacity: 1,
      transform: "translateX(0)",
    },
  });

const selectedCSS =
  ({ selectedRouteIndex }) =>
  ({ theme: { colors, shadows, radius } }) => ({
    position: "absolute",
    top: 0,
    right: 0,
    width: "100%",
    height: itemHeight,
    background: colors.primary,
    zIndex: 0,
    // boxShadow: shadows.big,
    ...(selectedRouteIndex >= 0 && {
      transform: `translateY(${selectedRouteIndex * 50}px)`,
    }),
    opacity: selectedRouteIndex >= 0 ? 1 : 0,
    transition: "all 150ms ease-in-out",
    borderRadius: radius,

    "& > *": {
      color: "white",
      fill: "white",
    },
  });

const activeVisitorsCSS = ({ theme: { colors, grid } }) => ({
  marginLeft: "auto",
  height: 30,
  // width: 30,
  padding: `0 ${2 * grid}px`,
  borderRadius: 15,
  background: colors.bg,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  // color: "white",
});

const settingsCSS = ({ theme: { grid, colors, radius, shadows } }) => ({
  width: "100%",
  // height: 100,
  background: colors.contentBg,
  borderRadius: radius,
  boxShadow: shadows.big,
  marginBottom: 2 * grid,
});

const profileCSS = ({ theme: { grid, colors, radius, shadows } }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: 2 * grid,
});

const profileItemCSS = ({ theme: { colors, grid, shadows } }) => ({
  // padding: `0 ${4 * grid}px`,
  position: "relative",
  display: "flex",
  textDecoration: "none",
  cursor: "pointer",
  padding: 2 * grid,
  zIndex: 1,
  height: 50,
  alignItems: "center",
  borderBottom: `1px solid ${colors.border}`,

  "> svg": {
    transition: "color 150ms ease-in-out",
    color: colors.body,
  },

  "&:hover": {
    background: colors.border,
  },

  "&:hover > span": {
    color: colors.primary,
  },

  "> label": {
    opacity: 0,
    transform: `translateX(${-grid}px)`,
    transition: "all 200ms ease-in-out",
  },

  "&:hover > label": {
    opacity: 1,
    transform: "translateX(0)",
  },
});

const trialCSS = ({ theme: { grid, colors, radius } }) => ({
  width: "100%",
  height: 100,
  background: colors.primary,
  borderRadius: radius,
});
