import React, { useState, useEffect } from "react";
import { useFela } from "react-fela";
import { View, Spinner, Text, Button, Link , Card, Input, Toggle, Spacer} from "ollui";
import { RiCheckFill } from "react-icons/ri";
import { useHistory } from "react-router-dom";
import { useStore } from "../../store";
import { LoginBackground } from "./background";
import { motion } from "framer-motion";
import { useForm } from "react-hook-form";
// import ReactSlider from "react-slider";
import ReactSlider from "react-input-slider";

export const Form = ({ isLoading, setIsLoading }) => {
  const { auth, paddle, refreshUser } = useStore();
  const {
    register,
    handleSubmit,
    watch,
    errors,
    clearErrors,
    setError,
  } = useForm();

  const [variant, setVariant] = useState("login");
  const [plan, setPlan] = useState("free");
  const [planTimeFrame, setPlanTimeFrame] = useState("monthly");
  const [sliderValue, setSliderValue] = useState(1);
  const history = useHistory();

  const email = watch("email");
  const password = watch("password");

  useEffect(() => {
    clearErrors();
  }, [variant]);

  const onSubmit = () => {
    if (variant === "login") {
      handleSubmitLogin();
    } else {
      handleSubmitSignup();
    }
  };

  const handleSubmitLogin = () => {
    setIsLoading(true);
    setTimeout(() => {
      auth.login({ email, password }).catch((error) => {
        setIsLoading(false);
        if (error && error.status === 401) {
          setError("password", {
            type: "manual",
            message: "Incorrect email or password, please try again",
          });
          return;
        }
        if (error && error.status === 429) {
          setError("password", {
            type: "manual",
            message: error.data,
          });
          return;
        }
        setError("password", {
          type: "manual",
          message:
            error && error.data
              ? error.data
              : "Something went wrong, please try again",
        });
      });
    }, 1000);
  };

  const openCheckout = () => {
    paddle.openCheckout(email, planTimeFrame);
  };

  const submitSignup = () => {
    setIsLoading(true);
    setTimeout(() => {
      auth.signup({ email, password }).catch((error) => {
        setIsLoading(false);
        if (error && error.status === 429) {
          setError("password", {
            type: "manual",
            message: error.data,
          });
          return;
        }
        setError("password", {
          type: "manual",
          message:
            error && error.data
              ? error.data
              : "Something went wrong, please try again",
        });
      });
    }, 1000);
  };

  const handleSubmitSignup = () => {
    // if (plan === "standard") {
    //   paddle.openCheckout(email, planTimeFrame, submitSignup);
    // } else {
    submitSignup();
    // }
  };

  return (
    <View
      extend={{
        opacity: isLoading ? 0 : 1,
        transition: "opacity 300ms ease-in-out",
      }}
    >
      {localStorage.getItem("yoyosignup") && (
        <View extend={tabsCSS}>
          <View
            extend={tabCSS({ active: variant === "login" })}
            onClick={() => setVariant("login")}
          >
            <Text variant="medium" bold={variant === "login"}>
              Login
            </Text>
          </View>
          <View
            extend={tabCSS({ active: variant === "signup" })}
            onClick={() => setVariant("signup")}
          >
            <Text variant="medium" bold={variant === "signup"}>
              New account
            </Text>
          </View>
        </View>
      )}
      {/* {variant === "login" && <Spacer size={6} />}
      {variant === "login" && <Spacer size={4} />} */}
      {/* {variant === "signup" && <Spacer size={2} />} */}
      <Spacer size={4} />
      <View
        as="form"
        action="#"
        onSubmit={handleSubmit(onSubmit)}
        autoComplete="on"
      >
        <Input
          type="email"
          placeholder="Email"
          name="email"
          register={register}
          errors={errors}
          options={{ required: true, pattern: /^\S+@\S+$/i }}
          id="email"
        />
        <Spacer size={2} />
        <Input
          type="password"
          placeholder="Password"
          name="password"
          register={register}
          errors={errors}
          options={{
            required: true,
            minLength: 8,
            maxLength: 40,
          }}
          id="password"
        />
        <input type="text" name="bannaa" style={{ display: "none" }} />
        {/* <Input
          type="password"
          placeholder="repeatPassword"
          name="repeatPassword"
          register={register}
          errors={errors}
          options={{
            required: true,
            minLength: 8,
            maxLength: 40,
          }}
          extend={{
            display: "none",
          }}
        /> */}
        <Spacer size={4} />
        <View extend={buttonContainerCSS}>
          <Spacer size={2} />
          <Button type="submit">
            {variant === "login" ? "Login" : "Start your free trial"}
          </Button>
        </View>
        {/* <Spacer />
        <Link
          type="button"
          as={motion.button}
          animate={{
            opacity: variant === "login" ? 1 : 0,
            // transform: `scale(${variant === "login" ? 1 : 0.8})`,
          }}
          extend={{
            marginLeft: "auto",
          }}
        >
          Forgotten your password?
        </Link> */}
      </View>
      <Spacer size={2} />
      {auth.loginError && (
        <>
          <Text>{auth.loginError}</Text>
          <Spacer size={2} />
        </>
      )}
      {/* <Spacer size={2} />
            <Button secondary onClick={() => setIsLogin(!isLogin)}>
              {isLogin ? "New user?" : "Have an account?"}
            </Button> */}
    </View>
  );
};
// {/* <View
//   as={motion.div}
//   animate={{
//     height: variant === "signup" ? "auto" : 0,
//     opacity: variant === "signup" ? 1 : 0,
//   }}
//   extend={plansContainerCSS}
// >
//   <Spacer size={5} />
//   <Text variant="medium" bold>
//     Pay only for what you use
//   </Text>
//   <Spacer size={3} />
//   <View extend={{ display: "flex", alignItems: "center" }}>
//     <Text variant="large" bold>
//       $1.00
//     </Text>
//     <Spacer />
//     <Text variant="medium" diffuse extend={{ fontStyle: "italic" }}>
//       +
//     </Text>
//     <Spacer />
//     <Text variant="large" bold>
//       {`$${(sliderValue * 0.99).toFixed(2)}`}
//     </Text>
//   </View>
//   <Spacer size={1} />
//   <Text variant="medium" diffuse extend={{ fontStyle: "italic" }}>
//     {`${sliderValue} site${sliderValue > 1 ? "s" : ""}`}
//   </Text>
//   <Spacer size={1} />
//   <Slider value={sliderValue} onChange={setSliderValue} xmin={1} />

//   <Spacer size={4} />
//   <View extend={{ textAlign: "right" }}>
//     <Text variant="medium" bold>
//       Sign up now for a free 14 day trial
//     </Text>
//     <Spacer />
//     <Text variant="body" diffuse extend={{ fontStyle: "italic" }}>
//       No credit card required
//     </Text>
//   </View>
//   <Spacer size={5} />
// </View> */}

const Slider = ({ value, onChange, ...props }) => {
  const {
    theme: { colors, shadows },
  } = useFela();

  return (
    <View extend={sliderCSS}>
      <ReactSlider
        axis="x"
        x={value}
        onChange={({ x }) => onChange(x)}
        {...props}
        styles={{
          container: {
            background: "hotpink",
          },
          track: {
            backgroundColor: colors.border,
            width: "100%",
            height: 6,
            borderRadius: 3,
          },
          active: {
            backgroundColor: colors.primary,
          },
          thumb: {
            width: 30,
            height: 30,
            background: colors.primary,
            boxShadow: shadows.big,
            border: `4px solid ${colors.bg}`,
          },
          disabled: {
            opacity: 0.5,
          },
        }}
      />
    </View>
  );
};

const sliderCSS = () => ({
  width: "100%",
  height: 30,
  display: "flex",
  alignItems: "center",
});

const Plan = ({ active, onClick, title, items, price, priceBefore }) => (
  <Card extend={planCSS({ active })} onClick={onClick}>
    <Text variant="medium" bold>
      {title}
    </Text>
    <Spacer size={2} />
    <View as="ul">
      {items.map((item) => (
        <View as="li" extend={planItemCSS}>
          <View extend={planItemDotCSS} />
          <Text>{item}</Text>
        </View>
      ))}
    </View>
    <Spacer size={2} />
    <View extend={planPriceCSS}>
      {priceBefore && (
        <>
          <Text variant="medium" extend={planPriceBeforeCSS}>
            {priceBefore}
          </Text>
          <Spacer />
        </>
      )}
      <Text variant="large">{price}</Text>
    </View>
    <View
      as={motion.div}
      animate={{
        // transform: `scale(${active ? 1 : 0})`,
        transform: `translate3d(${active ? 0 : 50}px, ${
          active ? 0 : -50
        }px, 0)`,
        opacity: active ? 1 : 0,
      }}
      extend={planCheckCSS}
    >
      <RiCheckFill size={24} />
    </View>
  </Card>
);

const SignupAnimate = ({ variant, children }) => (
  <>
    <View
      as={motion.div}
      animate={{
        opacity: variant === "signup" ? 1 : 0,
        height: variant === "signup" ? "auto" : 0,
      }}
      extend={{
        pointerEvents: variant === "signup" ? "auto" : "none",
      }}
    >
      {children}
    </View>
  </>
);

const tabsCSS = () => ({
  display: "flex",
  // border: "2px dotted hotpink",
});

const tabCSS = ({ active }) => ({ theme: { grid, colors } }) => ({
  flex: 1,
  // background: active ? "orange" : "yellow",
  textAlign: "center",
  padding: 2 * grid,
  borderBottom: "3px solid transparent",
  borderColor: active ? colors.primary : colors.border,
  cursor: "pointer",
  transition: "border-color 200ms ease-in-out",

  "&:hover": {
    borderColor: active ? colors.primaryDark : colors.border,
  },
});

const plansContainerCSS = ({ theme: { colors, grid } }) => ({
  background: colors.contentBg,
  padding: `0 ${3 * grid}px`,
  borderBottom: `3px solid ${colors.border}`,
  overflow: "hidden",
});

const planCSS = ({ active }) => ({ theme: { grid, colors, shadows } }) => ({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  // background: active ? "orange" : "yellow",
  // textAlign: "center",
  // padding: 2 * grid,
  border: `2px solid ${active ? colors.primary : "transparent"}`,
  cursor: "pointer",
  transition: "all 100ms ease-in-out",
  overflow: "hidden",

  ...(active && {
    boxShadow: shadows.small,
  }),

  "&:hover": {
    borderColor: active ? colors.primaryDark : colors.border,
  },
});

const planItemCSS = ({ theme: { colors, grid } }) => ({
  display: "flex",
  alignItems: "center",
  margin: `${grid}px 0`,
});

const planItemDotCSS = ({ theme: { colors, grid } }) => ({
  width: 4,
  height: 4,
  background: colors.primary,
  // borderRadius: "50%",
  marginRight: grid,
});

const planPriceCSS = ({ theme: { colors, grid } }) => ({
  display: "flex",
  alignItems: "center",
});

const planPriceBeforeCSS = ({ theme: { colors, grid } }) => ({
  // display: "flex",
  color: colors.bodySecondary,
  textDecoration: "line-through",
});

const planCheckCSS = ({ theme: { colors, grid, layout } }) => ({
  position: "absolute",
  top: -10,
  right: -10,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  paddingRight: 10,
  paddingTop: 10,
  width: 50,
  height: 50,
  background: colors.primary,
  color: "white",
  borderBottomLeftRadius: layout.radius,
});

const buttonContainerCSS = ({ theme: { breakpoints } }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",

  [breakpoints.onlyS]: {
    flexDirection: "column-reverse",
  },
});
