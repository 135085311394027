import React, { useMemo } from "react";
import ReactMapboxGl, {
  Marker,
  ZoomControl,
  Layer,
  Feature,
  Source,
  GeoJSONLayer,
} from "react-mapbox-gl";
import { motion } from "framer-motion";
import { VscLocation } from "react-icons/vsc";
import { LngLatBounds } from "mapbox-gl";
import { Link } from "react-router-dom";
// import DrawControl from "react-mapbox-gl-draw";
// import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import { useFela } from "react-fela";
import { View } from "ollui";
// import "mapbox-gl/dist/mapbox-gl.css";
import { useStore } from "../../../store";
import { CurrentPositionMarker } from "./current-position-marker";

const MapboxMap = ReactMapboxGl({
  accessToken:
    "pk.eyJ1Ijoib2xsZXN2IiwiYSI6IllQdDFvcEkifQ.Mjzq5Vc6iUwd2d6KnkM26A",
});

const centerCoordinates = [10, 49];
const initialZoom = [2];

const getBounds = (markers = [], routeCoordinates, hovered) => {
  // if (!hovered && markers.length < 2) {
  //   return markers.map((marker) => [Number(marker.lon), Number(marker.lat)]);
  // }
  const bounds = new LngLatBounds();
  // if (markers.length > 1) {
  //   markers.forEach((marker) => {
  //     bounds.extend([marker.lon, marker.lat]);
  //   });
  // }
  // console.log("routeCoordinates", routeCoordinates);
  if (!routeCoordinates || !routeCoordinates.length) {
    bounds.extend(centerCoordinates);
    return;
  }
  routeCoordinates.forEach((coordinates) => {
    bounds.extend(coordinates);
  });
  return bounds;
  // }
  // for (let index = 0; index < markers.length; index++) {
  //   const marker = markers[index];
  //   if (marker.id === hovered) {
  //     return [
  //       [Number(marker.lon), Number(marker.lat)],
  //       [Number(marker.lon) + 0.00001, Number(marker.lat) + 0.00001],
  //     ];
  //   }
  // }
  return [0, 0];
};

const Map = ({
  containerStyle,
  hovered,
  markers,
  currentPosition,
  selectedRouteIndex,
}) => {
  const { routes } = useStore();
  const { theme, renderer } = useFela();
  // const [isSingle, setIsSingle] = useState(false);
  const has_markers = (markers && markers.length > 0) || currentPosition;
  const routeCoordinates = !routes
    ? []
    : routes[selectedRouteIndex].points.map((d) => [d.lon, d.lat]);
  console.log("routeCoordinates", routeCoordinates);
  const bounds =
    has_markers &&
    routeCoordinates.length &&
    getBounds([...markers, currentPosition], routeCoordinates, hovered);
  // console.log("bounds", bounds);
  if (!has_markers && !routeCoordinates.length) {
    return null;
  }

  console.log("bounds", bounds);

  const onDrawCreate = ({ features }) => {
    console.log(features);
  };

  const onDrawUpdate = ({ features }) => {
    console.log(features);
  };

  // const keyframes = () => ({
  //   "0%": { transform: "scale(0.8)" },
  //   "80%": { transform: "scale(1)" },
  //   "100%": { transform: "scale(0.8)" },
  // });

  // const singleMarkerKeyframes = renderer.renderKeyframe(keyframes, {});

  return (
    <MapboxMap
      // style="mapbox://styles/ollesv/cjnn478b000ce2smwse51p383"
      style="mapbox://styles/mapbox/light-v9"
      className="map"
      containerStyle={
        containerStyle || {
          position: "absolute",
          top: 0,
          left: 0,
          height: "100%",
          width: "100%",
        }
      }
      fitBounds={bounds}
      fitBoundsOptions={{
        maxZoom: 16,
        padding: {
          top: 50,
          bottom: 50,
          right: 50,
          left: 50,
        },
      }}
      onStyleLoad={(map) => {
        setTimeout(() => {
          map.resize();
          map.getBounds();
          // addRoutes(map, routes, theme.colors.primary);
        }, 50);
      }}
      zoom={initialZoom}
      center={centerCoordinates}
    >
      <ZoomControl />
      {markers.map((marker) => (
        <Marker
          key={marker.time}
          coordinates={[marker.lon, marker.lat]}
          anchor="bottom"
        >
          <View
            extend={styles.marker({
              theme,
            })}
          ></View>
        </Marker>
      ))}
      {routeCoordinates && routeCoordinates.length && (
        <>
          <Marker key="start" coordinates={routeCoordinates[0]} anchor="center">
            <View
              extend={styles.marker({
                theme,
                // singleMarkerKeyframes,
              })}
            ></View>
          </Marker>
          <Marker
            key="end"
            coordinates={routeCoordinates[routeCoordinates.length - 1]}
            anchor="center"
          >
            <View
              extend={styles.marker({
                theme,
                // singleMarkerKeyframes,
              })}
            ></View>
          </Marker>
        </>
      )}
      {/* <DrawControl
        onDrawCreate={onDrawCreate}
        onDrawUpdate={onDrawUpdate}
        displayControlsDefault={false}
        controls={{
          trash: true,
          polygon: true,
        }}
      /> */}
      {/* {routes &&
        routes.map((d, index) => (
          <GeoJSONLayer
            id={`route-${index}`}
            data={{
              type: "Feature",
              properties: {},
              geometry: {
                type: "LineString",
                coordinates: d.points.map((d) => [d.lon, d.lat]),
              },
            }}
            symbolLayout={{
              "text-field": "{place}",
              "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
              "text-offset": [0, 0.6],
              "text-anchor": "top",
            }}
            lineLayout={{
              "line-join": "round",
              "line-cap": "round",
            }}
            linePaint={{
              "line-color":
                index === selectedRouteIndex
                  ? theme.colors.secondary
                  : "transparent",
              "line-width": index === selectedRouteIndex ? 4 : 2,
            }}
          />
        ))} */}
      {routes && routes.length && routes[selectedRouteIndex] && (
        <GeoJSONLayer
          id={`route-${selectedRouteIndex}`}
          data={{
            type: "Feature",
            properties: {},
            geometry: {
              type: "LineString",
              coordinates: routes[selectedRouteIndex].points.map((d) => [
                d.lon,
                d.lat,
              ]),
            },
          }}
          symbolLayout={{
            "text-field": "{place}",
            "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
            "text-offset": [0, 0.6],
            "text-anchor": "top",
          }}
          lineLayout={{
            "line-join": "round",
            "line-cap": "round",
          }}
          linePaint={{
            "line-color": theme.colors.secondary,
            "line-width": 4,
          }}
        />
      )}
      <CurrentPositionMarker />
      {/* 
      <Marker
        key={currentPosition.time}
        coordinates={[currentPosition.lon, currentPosition.lat]}
        anchor="bottom"
      >
        <View
          extend={styles.marker({
            theme,
          })}
        ></View>
      </Marker> */}

      {/* <GeoJSONLayer
        data={{
          type: "Feature",
          properties: {},
          geometry: {
            type: "LineString",
            coordinates: routeCoordinates,
          },
        }}
        symbolLayout={{
          "text-field": "{place}",
          "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
          "text-offset": [0, 0.6],
          "text-anchor": "top",
        }}
        lineLayout={{
          "line-join": "round",
          "line-cap": "round",
        }}
        linePaint={{
          "line-color": theme.colors.primary,
          "line-width": 2,
        }}
      /> */}
    </MapboxMap>
  );
};

// <Marker
//   key={marker.id}
//   coordinates={[marker.lon, marker.lat]}
//   anchor="bottom"
// >
//   <View
//     extend={styles.marker({
//       theme,
//     })}
//   ></View>
// </Marker>

const markerSize = 16;

const styles = {
  marker: ({ theme: { colors, shadows }, singleMarkerKeyframes }) => ({
    display: "block",
    width: markerSize,
    height: markerSize,
    background: colors.secondary,
    borderRadius: "50%",
    // transition: 'border 200ms ease-out 600ms',
    border: `4px solid ${colors.secondaryBg}`,
    boxShadow: shadows.big,
    // animationName: singleMarkerKeyframes,
    // animationIterationCount: "infinite",
    // animationDuration: "1200ms",

    // extend: [{
    //   condition: isHovered,
    //   style: {
    //     // zIndex: '100 !important'
    //   }
    // }]
  }),
  markerLabel: ({
    theme: { colors, layout, shadows },
    isHoveredInList,
    isHovered,
  }) => ({
    padding: `${layout.grid}px ${2 * layout.grid}px`,
    background: colors.secondaryBg,
    color: colors.body,
    border: `2px solid ${colors.primary}`,
    position: "absolute",
    top: markerSize / 2,
    left: markerSize / 2,
    zIndex: -1,
    width: "auto",
    whiteSpace: "nowrap",
    borderRadius: layout.radius,
    boxShadow: shadows.big,
    transform: "scale(0)",
    transformOrigin: "0 0",
    transition: `transform 200ms ease-out ${isHoveredInList ? 600 : 0}ms`,

    extend: [
      {
        condition: isHovered,
        style: {
          transform: "scale(1)",
        },
      },
    ],
    // +transform(scale(0))
    // transform-origin: 0 0
    // -webkit-transform-origin: 0 0
  }),
};

const currentPositionSize = 40;

const currentPositionCSS = ({ theme: { colors } }) => ({
  display: "block",
  // width: currentPositionSize,
  // height: currentPositionSize,
  // background: colors.primary,
  // borderRadius: "50%",
});

const pulseCSS = ({ theme: { breakpoints, grid, colors, radius } }) => ({
  position: "relative",
  width: currentPositionSize,
  height: currentPositionSize,
  borderRadius: "50%",
  background: colors.primary,
  margin: "0 auto",
});

const pulse2CSS = ({ theme: { breakpoints, grid, colors, radius } }) => ({
  position: "absolute",
  top: "15%",
  left: "15%",
  width: "70%",
  height: "70%",
  borderRadius: "50%",
  background: colors.primary,
  margin: "0 auto",
});

const pulse3CSS = ({ theme: { breakpoints, grid, colors, radius } }) => ({
  position: "absolute",
  top: "5%",
  left: "5%",
  width: "90%",
  height: "90%",
});

export default Map;
