import React from "react";
import { View } from "ollui";

export const Page = ({
  center,
  children,
  disableScroll,
  extend,
  noPadding,
  noPaddingTop,
  limitContentWidth,
}) => {
  return (
    <View
      extend={[
        rootCSS({
          center,
          disableScroll,
          noPadding,
          noPaddingTop,
        }),
        extend,
      ]}
    >
      {limitContentWidth ? (
        <View extend={contentCSS}>{children}</View>
      ) : (
        children
      )}
    </View>
  );
};

const rootCSS = ({ center, disableScroll, noPadding, noPaddingTop }) => ({
  theme: {
    colors,
    layout: { grid },
    breakpoints,
  },
}) => ({
  background: colors.contentBg,
  // position: "absolute",
  // top: 0,
  // left: 0,
  width: "100%",
  // height: "100%",
  padding: 4 * grid,
  extend: [
    {
      condition: center,
      style: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
    },
    {
      condition: !disableScroll,
      style: {
        overflowY: "auto",
        WebkitOverflowScrolling: "touch",
      },
    },
    {
      condition: disableScroll,
      style: {
        overflowY: "hidden",
      },
    },
    {
      condition: noPadding,
      style: {
        padding: 0,
      },
    },
    {
      condition: noPaddingTop,
      style: {
        paddingTop: 0,
      },
    },
  ],

  [breakpoints.onlyS]: {
    padding: 2 * grid,
  },
});

const contentCSS = ({
  theme: {
    layout: { maxContentWidth },
  },
}) => ({
  maxWidth: maxContentWidth,
  margin: "0 auto",
});
