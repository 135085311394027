import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import Axios from "axios";
import { useHistory } from "react-router-dom";
import { View, Card, Spinner, Text, Button, Spacer, Input } from "ollui";
import { useStore } from "../../store";
import { LoginBackground } from "../login/background";
// import {InvitationNewPassword } from './invitation-new-password'

export const Invitation = () => {
  const { auth, refreshUser } = useStore();
  const { token } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [inviteInformation, setInviteInformation] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const history = useHistory();
  const {
    register,
    handleSubmit,
    watch,
    errors,
    clearErrors,
    setError,
  } = useForm();

  const password = watch("password");

  // const getInvitationInformation = () => {
  //   if (auth.isAuthenticated) {
  //     return auth.confirmInvitation({ token }).then(({ data }) => {
  //       history.push("/");
  //       refreshUser();
  //     });
  //   }
  // };

  // useEffect(() => {
  //   if (auth.hasCheckedAuthenticationStatus) {
  //     getInvitationInformation();
  //   }
  // }, [auth]);

  const onSubmit = () => {
    console.log("submit");
    setIsLoading(true);
    Axios.put("/team/invite/confirmation", {
      password,
      token,
    })
      .then(() => {
        setIsLoading(false);
        history.push("/login");
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  return (
    <View extend={rootCSS}>
      {/* <View extend={darkBackgroundCSS} as={animated.div}>
        <Image src="/building.jpg" extend={imageCSS} />
      </View> */}
      <LoginBackground />

      {!showNewPassword && <Spinner />}

      {showNewPassword && (
        <View
          as={motion.div}
          initial="hidden"
          animate="visible"
          variants={{
            hidden: { opacity: 0, scale: 0 },
            visible: { opacity: 1, scale: 1 },
          }}
          transition={{
            delay: 2,
            duration: 0.5,
          }}
          extend={contentCSS}
        >
          <Card extend={cardCSS}>
            <View extend={logoCSS}>
              <View
                as="img"
                src="../logo.svg"
                extend={{
                  width: "100%",
                  // height: 60,
                }}
              />
            </View>
            <Spacer size={8} />
            <View extend={{ textAlign: "center" }}>
              <Text variant="medium" bold>
                Hello there!
              </Text>
              <Spacer size={2} />
              <Text>You have received an invitation from your team.</Text>
              <Spacer />
              <Text>
                To complete your registration, please add your new password
              </Text>
            </View>
            <Spacer size={6} />
            <form action="#" onSubmit={handleSubmit(onSubmit)}>
              <Input
                type="password"
                placeholder="Password"
                name="password"
                register={register}
                errors={errors}
                options={{
                  required: true,
                  minLength: 8,
                  maxLength: 40,
                }}
              />
              {/* <Spacer size={2} />
            <Input
              register={register}
              type="password"
              placeholder="Repeat password"
              name="repeatPassword"
              errors={errors}
              options={{
                required: true,
                minLength: 8,
                maxLength: 40,
              }}
            /> */}
              <Spacer size={4} />
              <View extend={buttonContainerCSS}>
                <Button type="submit">Create account</Button>
              </View>
            </form>
            <View
              extend={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 1,
                pointerEvents: "none",
                opacity: isLoading ? 1 : 0,
                transition: "opacity 300ms ease-in-out",
              }}
            >
              <Spinner />
            </View>
          </Card>
        </View>
      )}
    </View>
  );
};

const rootCSS = ({ theme: { grid } }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  display: "flex",
  width: "100%",
  height: "100%",
  justifyContent: "center",
  // alignItems: "center",
  padding: 2 * grid,
});

const contentCSS = ({ theme: { grid } }) => ({
  width: "100%",
  maxHeight: "100%",
  height: "100%",
  maxWidth: 800,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const cardCSS = ({ theme: { grid, breakpoints, shadows } }) => ({
  // padding: 40,
  width: "100%",
  maxHeight: "100%",
  overflowY: "scroll",
  padding: 8 * grid,
  boxShadow: shadows.big,
  // padding: `${3 * grid}px ${8 * grid}px ${8 * grid}px ${8 * grid}px`,

  [breakpoints.onlyS]: {
    padding: 3 * grid,
  },
  // opacity: 0,
});

const logoCSS = () => ({
  width: "40%",
  // height: "5vh",
  margin: "0 auto",
  // display: "flex",
  // justifyContent: "flex-end",
});

const buttonContainerCSS = ({ theme: { breakpoints } }) => ({
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",

  [breakpoints.onlyS]: {
    flexDirection: "column-reverse",
  },
});
