import React from "react";
import { Page, Card, Spacer, Text } from "ollui";
import PageHeader from "../../components/organisms/page-header";
import { useStore } from "../../store";

const Settings = () => {
  const { user } = useStore();

  return (
    <Page disableScroll limitContentWidth>
      <Card>
        <Text variant="medium">Settings</Text>
        <Spacer size={4} />
      </Card>
    </Page>
  );
};

export default Settings;
