import { useState, useEffect } from "react";
import axios from "axios";

export const useMembers = (isAuthenticated, selectedTeam) => {
  const [data, setData] = useState([]);

  const refreshData = () => {
    if (!isAuthenticated) {
      setData({});
      return;
    }

    if (!selectedTeam) {
      return;
    }

    axios
      .get(`/team/users/${selectedTeam.id}`)
      .then(({ data: responseData }) => {
        // console.log("data", responseData);
        setData(responseData);
      })
      .catch((err) => {
        // setLoginError(err.message);
      });
  };

  useEffect(() => refreshData(), [isAuthenticated, selectedTeam]);

  return { members: data, refreshMembers: refreshData };
};
