import Home from "./home";
import {
  RiLogoutBoxLine,
  RiLayoutGridLine,
  RiListSettingsLine,
  RiCommunityLine,
} from "react-icons/ri";
import Settings from "./settings";
import Profile from "./profile";
import Login from "./login";
import Logout from "./logout";

export default [
  {
    path: "/",
    title: "Sites",
    Component: Home,
    icon: RiLayoutGridLine,
    // skipInNavigation: true,
  },
  {
    path: "/settings",
    title: "Settings",
    Component: Settings,
    icon: RiListSettingsLine,
    showInSubNavigation: true,
    // adminOnly: true,
  },

  {
    path: "/profile",
    title: "Profil",
    Component: Profile,
    skipInNavigation: true,
  },
  {
    path: "/login",
    title: "Login",
    Component: Login,
    skipInNavigation: true,
  },
  {
    path: "/logout",
    title: "Logout",
    Component: Logout,
    icon: RiLogoutBoxLine,
    showInSubNavigation: true,
  },
];
